//@import 'variables';

$directions: top right bottom left;
@each $direction in $directions {
  @for $i from 0 through 50 {
    .padding-#{$direction}-#{$i} {
      padding-#{$direction}: #{$i}px !important;
    }
    .margin-#{$direction}-#{$i} {
      margin-#{$direction}: #{$i}px !important;
    }
  }
}

@for $i from 0 through 50 {
  .padding-#{$i} {
    padding: #{$i}px;
  }
  .margin-#{$i} {
    margin: #{$i}px;
  }
}

.content-center {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.opacity-down {
  opacity: 0.2;
}

.floating {
  position: fixed !important;
  right: 20px;
  bottom: 20px;  
}

table, table.mat-mdc-table {
    thead {
        tr {
            background: #f3e5f5;
            th, td {
                vertical-align: middle;
                color: #000000;
                font-size: 1em;
                padding-right: 25px;
                font-weight: bold;
            }
        }
    }
    
    tbody tr {
        td { 
            vertical-align: middle;
            font-size: 1em;
            padding-right: 25px;
            padding-left: 0;
        }
        
        td:first-of-type {
            padding-left: 24px;
        }
    }
}

#loading {
 // display: none !important;
}

.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;

}

.loader {
  color: #53AD32;
  font-size: 10px;
  display: block !important;
  position: absolute;
  right: 50%;
  top: 43%;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;  
  
}

.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0;
 
}

.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.loader:after {
  left: 3.5em;
}

@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

.load {
  width:100%;
  height:100%;
  //background: #313131  url('assets/img/load.gif') no-repeat center;
  background: #313131;
  opacity:.50;
  top:0;
  left:0;
  position:fixed;
  overflow:auto;  
  z-index: 2000  !important;
  display: block  !important;
  }