/* You can add global styles to this file, and also import other style files */
@import "./@fury/styles/core";
@import "./app/app.theme";
@import "./assets/sass/util";
@import "./assets/sass/variables";
@import '../node_modules/bootstrap/scss/bootstrap.scss';
@import "https://fonts.googleapis.com/icon?family=Material+Icons";

 .mat-drawer {
    z-index: 1 !important;
} 

.margen{
    margin-right: 12px !important;
    margin-top: 12px!important;
}

fury-quick-info-widget{
    margin-right: 12px !important;
    margin-top: 12px!important;
}

.quick-info-widget .icon {
    font-size: 40px !important;
    height: 48px;
    width: 48px;
}

.quick-info-widget .label {
    font-size: 11px !important;
}

@media (max-width: 700px) {
    .mat-drawer {
        z-index: 3 !important;
    } 
    .cdk-overlay-pane{
        width: 100% !important;
        max-width: 100%  !important;
    }
    .signature-pad-canvas{
        width: 100%  !important;
    }
}
.snack-bar-success{
    background: #68d453 !important;
   // color: #235e17 !important;
    text-align: justify;
    z-index: 999999 !important;
    .mat-mdc-snack-bar-action{
        color: #ffff !important;
    }
}
  
.snack-bar-warning{
    background: #dbcf2a !important;
   // color: #696824 !important;
   text-align: justify;
    z-index: 999999 !important;
    .mat-mdc-snack-bar-action{
        color: #ffff!important;
    }
}

.snack-bar-info{
    background: #4a8ff7 !important;
   // color: #696824 !important;
    text-align: justify;
    z-index: 999999 !important;
    .mat-mdc-snack-bar-action{
        color: #ffff !important;       
    }
}

 .snack-bar-error{
    background: #f76d6d !important;
  //  color: #773838 !important;
     text-align: justify;
     z-index: 999999 !important;
    .mat-mdc-snack-bar-action{
        color: #ffff !important;
    }
  }
 
.alertify .ajs-body .ajs-content {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.btn-primary {
    color: #fff !important;
    background-color: #388e3c !important;
    border-color: #388e3c !important;
}

.btn-primary:hover {
    color: #fff !important;
    background-color: #388e3c !important;
    border-color: #388e3c !important;
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
    color: #fff !important;
    background-color: #388e3c !important;
    border-color:#388e3c  !important;
}

.ajs-dialog{
    padding-right: 45px !important;
    padding-bottom: 11px !important;
}

.clean-borders{
    padding: 0px !important;
    margin: 0px !important;
}
  
 
