// Descriptive Colors
$white: #ffffff;
$blue: #3f51b5;
$dark-blue: #34495e;
$primary: #68d453  !default;
$red: #dc143c;
$grey: #eceff1;
$light-grey: #eeeeee;
$dark-grey: #CFD8DC;
$transparent: rgba(255,255,255,0.3);
$text-grey: #605f5f;
$black: #9c27b0;

// Functional Colors
$primary--color: $primary;
$secondary--color: $white;
$red--color: $red;

$menu-header: #888989;