fury-chat {

  .chat-drawer {
    border-right: 1px solid $theme-divider;
  }

  .reply {
    border-top: 1px solid $theme-divider;
  }
}
